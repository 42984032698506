import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import 'react-tabs/style/react-tabs.css'
import FinanceDeal from '../../Images/finance-deal.jpg'

function HomeAbout() {
    return (
        <div id="about"
            className="
            2xl:max-w-screen-xl lg:max-w-screen-lg
            lg:mx-auto
            lg:py-12 md:py-10 py-8
            md:px-4 px-2
        ">
            <div className="
                px-10
                py-5
            ">
                <div className="
                    flex
                    items-center
                    lg:flex-row flex-col
                ">
                    <div className="
                        lg:w-3/5
                        lg:mr-4
                    ">
                        <img src={FinanceDeal} alt="finance-deal" className="rr-financedeal-img">
                        </img>
                    </div>
                    <div className="
                        lg:flex
                        lg:justify-center
                        lg:transform
                        lg:-rotate-90
                    ">
                        <h2 className="
                            rr-about-title-text
                            lg:absolute
                            lg:py-0 py-4
                            text-5xl
                            uppercase
                            font-bold
                            text-green-900
                            opacity-25
                        ">
                            About Ronnel Roble
                        </h2>
                    </div>
                    <div className="
                        w-full
                        lg:text-left text-center
                        lg:ml-16
                    ">
                        <div className="lg:pb-10 md:pb-5 pb-2">
                            <h3 className="
                                uppercase
                                lg:text-4xl md:text-3xl
                                lg:pb-5 md:pb-4 pb-2
                                lg:px-0 px-5
                            ">
                                We Are Here To Manage Your Finance With Experience
                            </h3 >
                            <hr className="
                                border-2
                                w-32
                                border-green-400
                            ">
                            </hr>
                        </div>
                        <div className="
                            flex
                            md:pb-10 pb-0
                            md:flex-row flex-col
                        ">
                            <div className="
                                md:w-1/2
                                w-full
                                md:pb-0 pb-5
                                pr-5
                                text-green-900
                            ">
                                <div className="
                                    about-item-title-container
                                    flex
                                    items-center
                                ">
                                    <span className="
                                        text-xl
                                        pr-2
                                    ">
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                        />
                                    </span>
                                    <h4 className="
                                        pb-0
                                        md:pl-0 pl-4
                                        md:leading-7 leading-4
                                        lg:text-left md:text-center text-left
                                        md:block inline-block
                                        md:w-full w-2/3
                                        font-lato
                                        font-bold
                                        uppercase
                                        md:text-xl text-lg
                                    ">
                                        Fast Approval
                                    </h4>
                                </div>
                                <hr className="
                                    md:hidden block
                                    border-t-2
                                    border-purple-900
                                "/>
                                <p className="
                                    font-oswald
                                    font-light
                                    md:pt-0 pt-2
                                    lg:text-left md:text-center text-left
                                ">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel bibendum est, et consectetur massa.
                                </p>
                            </div>
                            <div className="
                                md:w-1/2
                                w-full
                                md:pb-0 pb-5
                                pr-5
                                text-green-900
                            ">
                                <div className="
                                    about-item-title-container
                                    flex
                                    items-center
                                ">
                                    <span className="
                                        text-xl
                                        pr-2
                                    ">
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                        />
                                    </span>
                                    <h4 className="
                                        md:pt-2
                                        pb-0
                                        md:pl-0 pl-4
                                        md:leading-7 leading-4
                                        lg:text-left md:text-center text-left
                                        md:block inline-block
                                        md:w-full w-2/3
                                        font-lato
                                        font-bold
                                        uppercase
                                        md:text-xl text-lg
                                    ">
                                        Refinancing
                                    </h4>
                                </div>
                                <hr className="
                                    md:hidden block
                                    border-t-2
                                    border-purple-900
                                "/>
                                <p className="
                                    font-oswald
                                    font-light
                                    md:pt-0 pt-2
                                    lg:text-left md:text-center text-left
                                ">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel bibendum est, et consectetur massa.
                                </p>
                            </div>
                        </div>
                        <div className="
                            flex
                            md:pb-10 pb-0
                            md:flex-row flex-col
                        ">
                            <div className="
                                md:w-1/2
                                w-full
                                md:pb-0 pb-5
                                pr-5
                                text-green-900
                            ">
                                <div className="
                                    about-item-title-container
                                    flex
                                    items-center
                                ">
                                    <span className="
                                        text-xl
                                        pr-2
                                    ">
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                        />
                                    </span>
                                    <h4 className="
                                        md:pt-2
                                        pb-0
                                        md:pl-0 pl-4
                                        md:leading-7 leading-4
                                        lg:text-left md:text-center text-left
                                        md:block inline-block
                                        md:w-full w-2/3
                                        font-lato
                                        font-bold
                                        uppercase
                                        md:text-xl text-lg
                                    ">
                                        Free Documentation
                                    </h4>
                                </div>
                                <hr className="
                                    md:hidden block
                                    border-t-2
                                    border-purple-900
                                "/>
                                <p className="
                                    font-oswald
                                    font-light
                                    md:pt-0 pt-2
                                    lg:text-left md:text-center text-left
                                ">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel bibendum est, et consectetur massa.
                                </p>
                            </div>
                            <div className="
                                md:w-1/2
                                w-full
                                md:pb-0 pb-5
                                pr-5
                                text-green-900
                            ">
                                <div className="
                                    about-item-title-container
                                    flex
                                    items-center
                                ">
                                    <span className="
                                        text-xl
                                        pr-2
                                    ">
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                        />
                                    </span>
                                    <h4 className="
                                        md:pt-2
                                        pb-0
                                        md:pl-0 pl-4
                                        md:leading-7 leading-4
                                        lg:text-left md:text-center text-left
                                        md:block inline-block
                                        md:w-full w-2/3
                                        font-lato
                                        font-bold
                                        uppercase
                                        md:text-xl text-lg
                                    ">
                                        24/7 Support
                                    </h4>
                                </div>
                                <hr className="
                                    md:hidden block
                                    border-t-2
                                    border-purple-900
                                "/>
                                <p className="
                                    font-oswald
                                    font-light
                                    md:pt-0 pt-2
                                    lg:text-left md:text-center text-left
                                ">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel bibendum est, et consectetur massa.
                                </p>
                            </div>
                        </div>
                        <div className="
                            flex
                            lg:pb-0 md:pb-10 pb-0
                            md:flex-row flex-col
                        ">
                            <div className="
                                md:w-1/3
                                w-full
                                md:pb-0 pb-5
                                pr-5
                                text-white
                            ">
                                <button className="
                                    font-yantramanav
                                    font-bold
                                    uppercase
                                    border-2
                                    border-green-900
                                    rounded-lg
                                    bg-green-900
                                    hover:bg-green-400
                                    hover:border-green-400
                                    px-12
                                    py-2
                                    mb-2
                                ">
                                    Contact
                                </button>
                            </div>
                            <div className="
                                md:w-2/3
                                w-full
                                md:pb-0 pb-5
                                pr-5
                                text-green-900
                            ">
                                <p className="
                                    font-oswald
                                    font-base
                                    pt-2
                                    lg:text-left md:text-center text-left
                                ">
                                    Call Us at: <span className="font-bold">240-9696-850</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeAbout