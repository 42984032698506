import React from 'react'
import ContactForm from './HomeContact/ContactForm';

function HomeContact() {
    return (
        <div id="contact"
            className="
            w-full
            lg:mx-auto
            lg:py-16 md:py-12 py-10
            md:px-4 px-2
            bg-green-50
        ">
            <div className="
                2xl:max-w-screen-xl
                lg:max-w-screen-lg
                md:max-w-screen-sm
                mx-auto
                md:px-0
                px-5
            ">
                <h2 className="
                font-lato
                uppercase
                lg:text-4xl md:text-3xl text-2xl
                text-center
                lg:pb-5 md:pb-4 pb-2
                lg:px-0 px-5
                ">
                    Drop us <span className="font-bold text-green-900">a message</span>
                </h2>
                <p className="
                font-oswald
                lg:text-xl md:text-xl text-lg
                font-light
                text-center
                lg:pb-5 md:pb-4 pb-2
                lg:px-28
                ">
                    If you have any questions about our services, leave a message here.
                </p>
            </div>
            <div className="
                lg:max-w-screen-md
                max-w-screen-sm
                mx-auto
                md:py-10 py-5
                md:px-10 px-2
                bg-white
                shadow-2xl
                rounded-xl

            ">
                <ContactForm />
            </div>
        </div>
    )
}

export default HomeContact