import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faHeartbeat,
    faChevronRight
} from '@fortawesome/free-solid-svg-icons'

function ProductHealth() {
    return (
        <div className="
            slide-container
            rounded-b-xl
            lg:m-3
            md:m-1
            m-5
            duration-500
        ">
            <div className="
                slide-content-container
                bg-white
                hover:bg-green-900
                hover:text-white
                lg:m-3 md:m-1 m-5
                py-8
                px-4
                rounded-xl
                shadow-xl
                duration-500
            ">
                <span className="text-5xl">
                    <FontAwesomeIcon
                        icon={faHeartbeat}
                    />
                </span>
                <h3 className="
                    font-lato
                    font-bold
                    text-2xl
                    uppercase
                    pt-8
                ">
                    Health
                </h3>
                <p className="
                    font-oswald
                    font-light
                    leading-5
                    py-4
                ">
                    Start looking out for yourself.  Learn more about Manulife Health products.
                </p>
                <div className="
                    flex
                    flex-wrap
                    justify-center
                ">
                    <div className="
                        flex
                        items-center
                        justify-center
                        w-full
                    ">
                        <Link
                            to={{ pathname: "https://www.manulife.com.ph/en/individual/products/health/health-flex.html" }}
                            target="_blank"
                            className="
                            font-medium
                            text-green-400
                            hover:underline
                            uppercase
                            cursor-pointer
                            pr-2
                        ">
                            HealthFlex
                        </Link>
                        <span className="
                            text-xs
                            text-green-400
                        ">
                            <FontAwesomeIcon
                                icon={faChevronRight}
                            />
                        </span>
                    </div>
                    <div className="
                        flex
                        items-center
                        justify-center
                        w-full
                    ">
                        <Link
                            to={{ pathname: "https://www.manulife.com.ph/en/individual/products/health/health-choice.html" }}
                            target="_blank"
                            className="
                            font-medium
                            text-green-400
                            hover:underline
                            uppercase
                            cursor-pointer
                            pr-2
                        ">
                            Health Choice
                        </Link>
                        <span className="
                            text-xs
                            text-green-400
                        ">
                            <FontAwesomeIcon
                                icon={faChevronRight}
                            />
                        </span>
                    </div>
                    <div className="
                        flex
                        items-center
                        justify-center
                        w-full
                    ">
                        <Link
                            to={{ pathname: "https://www.manulife.com.ph/en/individual/products/health/adam.html" }}
                            target="_blank"
                            className="
                            font-medium
                            text-green-400
                            hover:underline
                            uppercase
                            cursor-pointer
                            pr-2
                        ">
                            Adam
                        </Link>
                        <span className="
                            text-xs
                            text-green-400
                        ">
                            <FontAwesomeIcon
                                icon={faChevronRight}
                            />
                        </span>
                    </div>
                    <div className="
                        flex
                        items-center
                        justify-center
                    ">
                        <Link
                            to={{ pathname: "https://www.manulife.com.ph/en/individual/products/health/eve.html" }}
                            target="_blank"
                            className="
                            font-medium
                            text-green-400
                            hover:underline
                            uppercase
                            cursor-pointer
                            pr-2
                        ">
                            Eve
                        </Link>
                        <span className="
                            text-xs
                            text-green-400
                        ">
                            <FontAwesomeIcon
                                icon={faChevronRight}
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductHealth