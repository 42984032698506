import React, { Component } from 'react'
import Slider from "react-slick"
import ProductInvestment from './HomeProducts/ProductInvestment'
import ProductEducation from './HomeProducts/ProductEducation'
import ProductHealth from './HomeProducts/ProductHealth'
import ProductSavingsRetirement from './HomeProducts/ProductSavingsRetirement'
import ProductProtection from './HomeProducts/ProductProtection'
import ProductGroupProducts from './HomeProducts/ProductGroupProducts'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

export default class HomeProducts extends Component {
    render() {
        var settings = {
            className: "center",
            centerMode: true,
            dots: true,
            infinite: true,
            centerPadding: "0px",
            adaptiveHeight: true,
            focusOnSelect: true,
            lazyLoad: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 0
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div
            id="products"
            className="
                bg-rr-home-hero-1
                bg-cover
                bg-center
            ">
                <div className="
                    bg-black
                    lg:bg-opacity-80 bg-opacity-70
                ">
                    <div className="
                        rr-section-top-half
                        2xl:max-w-screen-xl
                        lg:max-w-screen-lg
                        lg:mx-auto
                        lg:py-16 md:py-12 py-10
                        md:px-4 px-2
                    ">
                        <h2 className="
                            text-white
                            uppercase
                            lg:text-5xl md:text-4xl
                            text-center
                            lg:pb-5 md:pb-4 pb-2
                            lg:px-0 px-5
                        ">
                            Products Offered
                        </h2 >
                        <div className="
                            flex
                            content-center
                            justify-center
                            lg:pb-5 md:pb-4 pb-2
                        ">
                            <hr className="
                            border-2
                            w-32
                            border-green-400
                            ">
                            </hr>
                        </div>
                        <p className="
                            text-white
                            lg:text-xl md:text-xl text-lg
                            font-light
                            text-center
                            lg:pb-5 md:pb-4 pb-2
                            lg:px-28
                        ">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae condimentum lacus, vel scelerisque tortor. Morbi imperdiet nisl sapien, eu egestas ligula pretium eget.
                        </p>
                        <div className="
                            text-center
                            text-green-900
                        ">
                            <Slider {...settings}>
                                <ProductInvestment />

                                <ProductEducation />

                                <ProductHealth />

                                <ProductSavingsRetirement />

                                <ProductProtection />

                                <ProductGroupProducts />
                            </Slider>
                        </div>
                    </div>
                </div >
                <div className="
                    rr-section-bottom-half
                    bg-white
                ">

                </div>
            </div >
        );
    }
}