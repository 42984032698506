import React from 'react'

function Footer() {
    const today = new Date();

    return (
        <footer className="
            bg-green-900
            p-3
            absolute
            bottom-0
            w-full
            font-yantramanav
        ">
            <div className="
                text-white
                text-center
                text-xs
                lg:max-w-screen-lg
                lg:mx-auto
            ">
                &copy; Copyright {today.getFullYear()} Ronnel Roble
            </div>
        </footer>
    )
}

export default Footer