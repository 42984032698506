import {
    React, useState, useEffect
} from 'react'
import Navigation from './Navigation'
import NavigationMobile from './NavigationMobile'
import { Link } from 'react-router-dom'
import rrlogo from '../Images/logo-rr.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFacebookF, faTwitter, faInstagram, faLinkedin
} from '@fortawesome/free-brands-svg-icons'
import {
    faEnvelope, faPhone
} from '@fortawesome/free-solid-svg-icons'

function Header() {
    const [isVisible, setIsVisible] = useState(true);
    const [height, setHeight] = useState(0)

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
    })

    const listenToScroll = () => {
        let heightToHideFrom = 250;
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;
        setHeight(winScroll);

        if (winScroll > heightToHideFrom) {
            isVisible && setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };

    return (
        <header className="
            border-b
            bg-white
            md:sticky
            md:top-0
            md:z-50
        ">
            <b className="hidden">height: {height} - {isVisible ? "show" : "hide"}</b>
            {
                isVisible
                &&
                <div className="
                    bg-gradient-to-r
                    from-green-400
                    via-green-900
                    to-green-900
                    py-2
                ">
                    <div className="
                        2xl:max-w-screen-xl lg:max-w-screen-lg
                        lg:mx-auto
                        md:flex
                        justify-between
                        items-center
                        lg:px-0 px-4
                    ">
                        <div>
                            <ul className="
                                flex
                                md:justify-start justify-center
                            ">
                                <li className="
                                    text-white
                                    hover:text-green-400
                                    px-2
                                ">
                                    <FontAwesomeIcon
                                        icon={faEnvelope}
                                    />
                                </li>
                                <li className="
                                    text-white
                                    hover:text-green-400
                                    pr-2
                                ">
                                    <Link to={{ pathname: "mailto:info@ronnelroble.tk" }}>
                                        info@ronnelroble.tk
                                    </Link>
                                </li>
                                <li className="
                                    text-white
                                    hover:text-green-400
                                    px-2
                                ">
                                    <FontAwesomeIcon
                                        icon={faPhone}
                                    />
                                </li>
                                <li className="
                                    text-white
                                    hover:text-green-400
                                ">
                                    <Link to={{ pathname: "tel:+639101234567" }}>
                                        09101234567
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <ul className="
                                flex
                                md:justify-start justify-center
                                md:pt-0 pt-2
                            ">
                                <li className="
                                    text-white
                                    hover:text-green-400
                                    px-2
                                ">
                                    <Link to={{ pathname: "https://www.facebook.com/" }}>
                                        <FontAwesomeIcon
                                            icon={faFacebookF}
                                        />
                                    </Link>
                                </li>
                                <li className="
                                    text-white
                                    hover:text-green-400
                                    px-2
                                ">
                                    <Link to={{ pathname: "https://twitter.com/" }}>
                                        <FontAwesomeIcon
                                            icon={faTwitter}
                                        />
                                    </Link>
                                </li>
                                <li className="
                                    text-white
                                    hover:text-green-400
                                    px-2
                                ">
                                    <Link to={{ pathname: "https://www.instagram.com/" }}>
                                        <FontAwesomeIcon
                                            icon={faInstagram}
                                        />
                                    </Link>
                                </li>
                                <li className="
                                    text-white
                                    hover:text-green-400
                                    px-2
                                ">
                                    <Link to={{ pathname: "https://www.linkedin.com/" }}>
                                        <FontAwesomeIcon
                                            icon={faLinkedin}
                                        />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            }
            <div className="
                flex
                lg:justify-start justify-between
                items-center
                2xl:max-w-screen-xl lg:max-w-screen-lg
                lg:mx-auto
                py-2
                lg:px-0 px-4
            ">
                <Link
                    to="/"
                    className="lg:w-1/5"
                >
                    <img
                        src={rrlogo}
                        alt="Ronnel Roble"
                        className="
                            lg:w-1/4 h-1/4
                            md:w-1/12 h-1/12
                            w-1/6 h-1/6
                            transform
                            lg:hover:scale-110
                    ">
                    </img>
                </Link>

                <Navigation />
                <NavigationMobile />
            </div>
        </header>
    )
}

export default Header