import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faUserShield,
    faChevronRight
} from '@fortawesome/free-solid-svg-icons'

function ProductProtection() {
    return (
        <div className="
            slide-container
            rounded-b-xl
            lg:m-3
            md:m-1
            m-5
            duration-500
        ">
            <div className="
                slide-content-container
                bg-white
                hover:bg-green-900
                hover:text-white
                lg:m-3 md:m-1 m-5
                py-8
                px-4
                rounded-xl
                shadow-xl
                duration-500
            ">
                <span className="text-5xl">
                    <FontAwesomeIcon
                        icon={faUserShield}
                    />
                </span>
                <h3 className="
                            font-lato
                            font-bold
                            text-2xl
                            uppercase
                            pt-8
                        ">
                    Protection
                </h3>
                <p className="
                    font-oswald
                    font-light
                    leading-5
                    py-4
                ">
                    Secure the future of your loved ones. Learn more about Manulife Life Insurance products.
                </p>
                <div className="
                    flex
                    flex-wrap
                    justify-center
                ">
                    <div className="
                        flex
                        items-center
                        justify-center
                        w-full
                    ">
                        <Link
                            to={{ pathname: "https://www.manulife.com.ph/en/individual/products/protection-life-insurance/horizons.html" }}
                            target="_blank"
                            className="
                            font-medium
                            text-green-400
                            hover:underline
                            uppercase
                            cursor-pointer
                            pr-2
                        ">
                            Horizons
                        </Link>
                        <span className="
                            text-xs
                            text-green-400
                        ">
                            <FontAwesomeIcon
                                icon={faChevronRight}
                            />
                        </span>
                    </div>
                    <div className="
                        flex
                        items-center
                        justify-center
                        w-full
                    ">
                        <Link
                            to={{ pathname: "https://www.manulife.com.ph/en/individual/products/protection-life-insurance/seasons100.html" }}
                            target="_blank"
                            className="
                            font-medium
                            text-green-400
                            hover:underline
                            uppercase
                            cursor-pointer
                            pr-2
                        ">
                            Seasons 100
                        </Link>
                        <span className="
                            text-xs
                            text-green-400
                        ">
                            <FontAwesomeIcon
                                icon={faChevronRight}
                            />
                        </span>
                    </div>
                    <div className="
                        flex
                        items-center
                        justify-center
                        w-full
                    ">
                        <Link
                            to={{ pathname: "https://www.manulife.com.ph/en/individual/products/protection-life-insurance/yrt-react5.html" }}
                            target="_blank"
                            className="
                            font-medium
                            text-green-400
                            hover:underline
                            uppercase
                            cursor-pointer
                            pr-2
                        ">
                            YRT and ReAct5
                        </Link>
                        <span className="
                            text-xs
                            text-green-400
                        ">
                            <FontAwesomeIcon
                                icon={faChevronRight}
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductProtection