import React from 'react'
import { Link as Link2 } from 'react-scroll'

function HomeHero() {
    return (
        <div className="
            w-full
            flex
        ">
            <div className="
                bg-rr-home-hero-1
                bg-cover
                bg-right
                lg:w-1/2
            ">
                <div className="
                    bg-black
                    lg:bg-opacity-90 bg-opacity-80
                    lg:py-56 md:py-44 py-16
                ">
                    <div className="
                        hero-column-1
                        lg:ml-auto
                        lg:mr-0
                        mx-auto
                        lg:pl-0
                        lg:pr-8
                        md:px-12 px-6
                        2xl:max-w-screen-lg lg:max-w-screen-md
                        text-white
                    ">
                        <h1 className="
                            lg:text-6xl md:text-5xl text-4xl
                            font-bold
                            text-left
                            md:pb-10 pb-5
                            uppercase
                        ">
                            Best Financial Solutions
                            <span className="
                                block
                                pt-2
                                text-2xl
                                normal-case
                                font-normal
                                font-oswald
                            ">
                                <ul className="
                                    flex
                                    items-center
                                ">
                                    <li className="
                                        w-16 pr-2
                                    ">
                                        <hr className="
                                            border-2
                                            border-green-400
                                        ">
                                        </hr>
                                    </li>
                                    <li>For You and Your Family</li>
                                </ul>
                            </span>
                        </h1>
                        <p className="
                            md:text-xl text-lg
                            font-light
                            text-left
                            md:pb-10 pb-5
                        ">
                            We provide quality financial advisory services. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec arcu orci, vehicula in diam sed, porta vulputate nibh.
                        </p>
                        <div className="text-left">
                            <Link2
                                to="contact"
                                spy={true}
                                smooth={true}
                            >
                                <button className="
                                    font-yantramanav
                                    font-bold
                                    uppercase
                                    border-2
                                    border-green-900
                                    rounded-lg
                                    bg-green-900
                                    hover:bg-green-400
                                    hover:border-green-400
                                    px-12
                                    py-2
                                    mb-2
                                ">
                                    Contact
                                </button>
                            </Link2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="
                hero-column-2
                bg-black
                bg-opacity-50
                lg:py-64 md:py-44 py-16
                lg:w-1/2
                bg-rr-home-hero-2
                bg-cover
                bg-left
            ">
            </div>
        </div>
    )
}

export default HomeHero