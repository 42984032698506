import React from 'react'
import { Link as Link1 } from 'react-router-dom'
import { Link as Link2 } from 'react-scroll'

function NavigationMenu(props) {
    return (
        <nav className="font-yantramanav">
            <div className="
                font-bold
                py-3
                uppercase
                lg:hidden
                text-right
            ">
                Ronnel Roble
            </div>
            <ul className="
                lg:flex
                lg:justify-end
            ">
                <li className="lg:hidden">
                    <Link1
                        to="/"
                        className="
                        text-green-900
                        py-2
                        lg:border-t-0
                        border-t
                        lg:border-b-0
                        border-b
                        lg:hover:text-green-400
                        block
                        font-semibold
                        cursor-pointer
                        text-right
                        "
                        onClick={(props.closeMenu)}
                    >
                        Home
                    </Link1>
                </li>
                <li className="
                    lg:inline-block
                    lg:pr-8
                ">
                    <Link2
                        to="about"
                        spy={true}
                        smooth={true}
                        className="
                        text-green-900
                        py-2
                        lg:border-t-0
                        border-t
                        lg:border-b-0
                        border-b
                        lg:hover:text-green-400
                        block
                        font-semibold
                        cursor-pointer
                        text-right
                        "
                        onClick={(props.closeMenu)}
                    >
                        About
                    </Link2>
                </li>
                <li className="
                    lg:inline-block
                    lg:pr-8
                ">
                    <Link2
                        to="products"
                        spy={true}
                        smooth={true}
                        className="
                        text-green-900
                        py-2
                        lg:border-t-0
                        border-t
                        lg:border-b-0
                        border-b
                        lg:hover:text-green-400
                        block
                        font-semibold
                        cursor-pointer
                        text-right
                        "
                        onClick={(props.closeMenu)}
                    >
                        Products
                    </Link2>
                </li>
                {/* <li className="
                    lg:inline-block
                    lg:pr-8
                ">
                    <Link2
                        to="portfolio"
                        spy={true}
                        smooth={true}
                        className="
                        text-green-900
                        py-2
                        lg:border-t-0
                        border-t
                        lg:border-b-0
                        border-b
                        lg:hover:text-green-400
                        block
                        font-semibold
                        cursor-pointer
                        text-right
                        "
                        onClick={(props.closeMenu)}
                    >
                        Portfolio
                    </Link2>
                </li> */}
                {/* <li className="
                    lg:inline-block
                    lg:pr-8
                ">
                    <Link2
                        to="contact"
                        spy={true}
                        smooth={true}
                        className="
                        text-green-900
                        py-2
                        lg:border-t-0
                        border-t
                        lg:border-b-0
                        border-b
                        lg:hover:text-green-400
                        block
                        font-semibold
                        cursor-pointer
                        text-right
                        "
                        onClick={(props.closeMenu)}
                    >
                        Contact
                    </Link2>
                </li> */}
                <li className="lg:inline-block">
                    <Link2
                        to="contact"
                        spy={true}
                        smooth={true}
                        className="
                        lg:text-white text-green-900
                        py-2
                        lg:px-6
                        lg:border-t-0 border-t
                        lg:border-b-0 border-b
                        lg:hover:bg-green-400
                        block
                        font-semibold
                        cursor-pointer
                        lg:bg-green-600
                        lg:rounded-lg
                        text-right
                        "
                        onClick={(props.closeMenu)}
                    >
                        Get A Quote
                    </Link2>
                </li>
            </ul>
        </nav>
    )
}

export default NavigationMenu