import React, { useState } from 'react';
import axios from 'axios';

const ContactForm = () => {
    const [state, setState] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [result, setResult] = useState(null);

    const sendEmail = event => {
        event.preventDefault();
        axios
            .post('/send', { ...state })
            .then(response => {
                setResult(response.data);
                setState({
                    name: '',
                    email: '',
                    message: ''
                });
            })
            .catch(() => {
                setResult({
                    success: false,
                    message: 'Something went wrong. Try again later'
                });
            });
    };

    const onInputChange = event => {
        const { name, value } = event.target;

        setState({
            ...state,
            [name]: value
        });
    };

    return (
        <form name="contact" onSubmit={sendEmail}>
            {result && (
                <p className={`text-center pb-5 ${result.success ? 'success' : 'error'}`}>
                    {result.message}
                </p>
            )}
            <input type="hidden" name="form-name" value="contact" />
            <div className="
                flex
                md:flex-row flex-col
            ">
                <div className="md:w-1/2 w-full px-2">
                    <div className="py-2">
                        <input
                            type="text"
                            id="name"
                            name="name"
                            required placeholder="Name"
                            className="
                                border-b
                                border-green-900
                                w-full
                                p-2
                                placeholder-green-900
                                placeholder-opacity-50
                                text-green-900
                                font-bold
                            "
                            onChange={onInputChange}
                        />
                    </div>
                    <div className="py-2">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            required
                            placeholder="Email"
                            className="
                                border-b
                                border-green-900
                                w-full
                                p-2
                                placeholder-green-900
                                placeholder-opacity-50
                                text-green-900
                                font-bold
                            "
                            onChange={onInputChange}
                        />
                    </div>
                </div>
                <div className="md:w-1/2 w-full px-2 py-2">
                    <div className="md:h-full">
                        <textarea
                            id="message"
                            name="message"
                            required
                            placeholder="Message"
                            className="
                                border
                                border-green-900
                                w-full
                                md:h-full
                                h-32
                                p-2
                                placeholder-green-900
                                placeholder-opacity-50
                                text-green-900
                                font-bold
                            "
                            onChange={onInputChange}
                        />
                    </div>
                </div>
            </div>
            <div className="text-center md:mt-10 mt-2">
                <button type="submit"
                    className="
                    font-lato
                    font-bold
                    uppercase
                    border-2
                    border-green-900
                    rounded-full
                    bg-green-900
                    text-white
                    hover:bg-green-400
                    hover:border-green-400
                    px-12
                    py-2
                ">
                    Submit
                </button>
            </div>
        </form>
    );
};

export default ContactForm