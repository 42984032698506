import React from 'react'
import LazyLoad from 'react-lazyload';
import HomeHero from '../Components/Home/HomeHero'
import HomeProducts from '../Components/Home/HomeProducts'
// import HomeCta1 from '../Components/Home/HomeCta1'
// import HomePortfolio from '../Components/Home/HomePortfolio'
// import HomeCta2 from '../Components/Home/HomeCta2'
import HomeAbout from '../Components/Home/HomeAbout'
import HomeContact from '../Components/Home/HomeContact'

// const schemaData =
// {
//     "@context": "http://www.schema.org",
//     "@type": ["Organization", "Website"],
//     "name": "Owltek Solutions",
//     "url": "https://owlteksolutions.com/",
//     "sameAs": [
//         "https://www.facebook.com/owlteksol/"
//     ],
//     "logo": "https://owlteksolutions.com/static/media/logo-owltek.81677cc6.png",
//     "image": "https://owlteksolutions.com/static/media/owltek-home-hero.6816e98c.jpg",
//     "description": "The best website for the best in the business - we customize company websites for success from initial development to ongoing improvement.",
//     "foundingDate": "2020",
//     "address": {
//         "@type": "PostalAddress",
//         "areaServed": "Davao Region",
//         "addressRegion": "Davao del Sur",
//         "addressCountry": "PH"
//     },
//     "openingHours": "Su, Mo, Tu, We, Th, Fr, Sa 09:00-21:00",
//     "telephone": "+63 (977) 038-9201",
//     "email": "info@owlteksolutions.com"
// }

function Home() {
    return (
        <>
            {/* <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
            /> */}
            
            <HomeHero />

            <div id="about">
                <LazyLoad
                    height={801}
                    offset={100}
                >
                    <HomeAbout />
                </LazyLoad>
            </div>

            <div id="services">
                <LazyLoad
                    height={583}
                    offset={-200}
                >
                    <HomeProducts />
                </LazyLoad>
            </div>

            {/* <LazyLoad
                height={308}
                offset={-200}
            >
                <HomeCta1 />
            </LazyLoad> */}

            {/* <div id="portfolio">
                <LazyLoad
                    height={631}
                    offset={100}
                >
                    <HomePortfolio />
                </LazyLoad>
            </div> */}

            {/* <LazyLoad
                height={308}
                offset={100}
            >
                <HomeCta2 />
            </LazyLoad> */}

            <div id="contact">
                <LazyLoad
                    height={542}
                    offset={100}
                >
                    <HomeContact />
                </LazyLoad>
            </div>

        </>
    )
}

export default Home