import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from './Components/Header'
import Footer from './Components/Footer'
import Home from './Views/Home'
// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-120546787-5');
// ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <div className="relative pb-10 min-h-screen">
      <Router>
        <Header />

        <div>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/#services">

            </Route>
            <Route path="/#about">

            </Route>
            <Route path="/#portfolio">

            </Route>
            <Route path="/#contact">
              
            </Route>
          </Switch>
        </div>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
